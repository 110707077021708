/* Menu.css */

.menu {
    display: flex;
    justify-content: start;
    /* animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both; */
    /* margin-left: 2rem; */
  }
  /* @keyframes text-focus-in {
    0% {
      -webkit-filter: blur(12px);
              filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
              filter: blur(0px);
      opacity: 1;
    }
  } */
  
  .menu ul {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 0;
    gap: 0.5rem;
  }
  
  .menu li {
    list-style: none;
    cursor: pointer;
  }
  
  .menu li a {
    color: white;
    text-decoration: none;
    font-size: 1.3rem;
    transition: color 0.3s ease;
    font-weight: 500;
    margin: 0 auto;
  }
  
  .menu li p:hover {
    color: rgb(255, 251, 0);
  }
  
  /* Desktop view adjustments */
  @media (min-width: 768px) {
    .menu ul {
      flex-direction: column;
      gap: 0.5rem;
    }
  
    h1 {
      font-size: 2.5rem;
    }
  }
  