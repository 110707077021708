/* Header.css */

@import url('https://fonts.googleapis.com/css2?family=Revalia&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:wght@500;700;900&display=swap');

/* Container for the video */
.header-container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  
  /* Video styling */
  .background-video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: 100%;
    min-width: 100%;
    transform: translate(-50%, -50%);
    object-fit: cover;
  }
  
  /* Content overlay */
  .content {
    position: absolute;
    top: 55%;
    left: 45%;
    transform: translate(-50%, -50%);
    color: rgb(255, 251, 0);
    text-align: center;
    padding: 1rem;
    z-index: 1;
    width: 90%;
    max-width: 800px;
    margin: 0 auto;
  }
  
  @keyframes text-focus-in {
    0% {
      -webkit-filter: blur(12px);
              filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
              filter: blur(0px);
      opacity: 1;
    }
  }

  .logo-wrapper{
    position: absolute;
    top: 20%;
    left: 39%;
    transform: translate(-50%, -50%);
    color: rgb(255, 251, 0);
    text-align: center;
    padding: 1rem;
    z-index: 1;
    width: 90%;
    max-width: 800px;
    margin: 0 auto;
  }
  .sls-logo {
    width: 80%;
    display: flex;
    align-self: start;
    animation: text-focus-in 2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;

  }
  
  /* Mobile-first menu styling */
   /* .menu ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

  } */
  
  /* .menu li a {
    color: white;
    text-decoration: none;
    font-size: 1.3rem;
    font-weight: bold;
  }  */

  @media (min-width: 768px) {
   .header-text {
    font-size: 4rem;
   }
   .sls-logo {
    width: 40%;
  }

  .content {
    top: 68%;
    left: 42%;
  }

  .logo-wrapper{
    top: 30%;
    left: 39%;
  }
  
  }

  @media (min-width: 1500px) {
    .sls-logo {
      width: 55%;
    }
    .logo-wrapper{
      top: 30%;
      left: 40%;
    }

  }
  
  