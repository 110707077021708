/* General styles (Mobile First) */
.video-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding: 1.5rem;
    background-image: url('../../../public/image/bird.JPG');
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    height: 100vh;
    overflow-y: auto;
  }
  .video-page nav{
    align-self: start;
  }
  
  
  .video-container {
    max-width: 100%; 
    width: 100%;
   
  }
  
  .youtube-video {
    width: 100%;
    height: 250px; /* Smaller height for mobile */
    border: none; /* Remove border */
    padding: 1rem 0 1rem 0;
  }
  
  /* Desktop styles */
  @media (min-width: 768px) {
    .video-page {
      display: grid;
      grid-template-columns: repeat(1, 2fr);
      padding-left: 20%;
      padding-right: 10%;
      padding-top: 10%;
    }
    .video-page nav{
      /* padding-top: 0.2rem ; */
      padding-right: 1rem;
      grid-column: 1;
    }
    .video-container{
      margin-top: -1rem;
      width:79%;
      grid-column: 2;
    }
    .youtube-video {
      height: 400px; /* Larger height for desktop */
    }
  }
  
  /* Larger screens (Desktops and large tablets) */
  @media (min-width: 1500px) {
    .video-page {
      display: grid;
      grid-template-columns: repeat(1, 2fr);
      padding-left: 25%;
      padding-right: 30%;
      padding-top: 10%;
    }
  
    .video-container {
      width:100%;
      grid-column: 2;
    }
  
    
  }
  
  