

.about-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding: 1.5rem;
    background-image: url('../../../public/image/gbg.JPG');
    background-size: cover;
    background-attachment: fixed;
    background-position: start;
    height: 100vh;
    overflow-y: auto;
  }
  .about-page nav{
    align-self: start;
  }
  
  .about-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 2rem;
    background-color: rgba(0, 0, 0, 0.7); 
    padding: 1.5rem; 
    
  }

  .about-container img{
    width: 100%;
  }
  .about-container p{
    margin-top: 1rem;
    margin-bottom: 0;
    color: white;
  }
  
  @media (min-width: 768px){
    .about-page{
      display: grid;
      grid-template-columns: repeat(1, 2fr);
      padding-left: 20%;
      padding-top: 10%;
    }
    .about-page nav{
      padding-right: 1rem;
      grid-column: 1;
    }
    .about-container{
      margin-top: 0;
      width: 65%;
      grid-column: 2;
      margin-bottom: 2rem;
    }
   
  }

  @media (min-width: 1500px) {
    .about-page {
      display: grid;
      grid-template-columns: repeat(1, 2fr);
      padding-left: 25%;

    }
  }
  
    
  