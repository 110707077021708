

.music-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: 1.5rem;
  background-image: url('../../../public/image/blacknwhite1.JPG');
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  height: 100vh;
  overflow-y: auto;
}
.music-page nav{
  align-self: start;
}

.album-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 2rem;
  background-color: rgba(0, 0, 0, 0.7); 
  padding: 0rem 1.5rem 1.5rem 1.5rem; 
  
}

  .album-img{
    width: 100%
  
  }

  .album-container h2 {
    color: white;
    margin-bottom: 0;
    margin-top: 0.5rem;
    align-self: start;

  }

  .album-container p {
    font-size: 16px;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    text-decoration: none;
    color: white;
    align-self: start;
    
  }
  .single-album{
    padding: 1.5rem 0 1.5rem 0;
    border-bottom: 1px solid white;

  }
  .single-album a{
    text-decoration: none;
    color: white;
    
  }

  @media (min-width: 768px){
    .music-page{
      display: grid;
      grid-template-columns: repeat(1, 2fr);
      padding-left: 20%;
      padding-top: 10%;
    }
    .music-page nav{
      padding-right: 1rem;
      grid-column: 1;
    }
    .album-container{
      margin-top: 0;
      width: 70%;
      grid-column: 2;
    }
    .single-album{
      display: flex;
      flex-direction: row;
   }
    .album-img{
      width: 35%;
      margin-right: 1.5rem;
    }
  }

  @media (min-width: 1500px) {
    .music-page {
      display: grid;
      grid-template-columns: repeat(1, 2fr);
      padding-left: 25%;

    }
  }


  
